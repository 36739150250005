import request from '@/http/request';

// 服务器退役--------
//一级机房列表
export function deptTree () {
  return request({
    url: `/system/dept/tree`,
    method: 'GET',
  });
}
// 退役工单列表
export function retireList(data) {
  return request({
    url: `/tickets/retire`,
    method: 'POST',
    data,
  });
}
//详情
export function retireDetail(ticketId) {
  return request({
    url: `/tickets/retire/detail?ticketId=${ticketId}`,
    method: 'GET',
  });
}
// 催办
export function remind(data) {
  return request({
    url: `/tickets/remind`,
    method: 'POST',
    data,
  });
}

//催办记录列表
export function getRemindList(params) {
  return request({
    url: `/tickets/remind`,
    method: 'GET',
    params
  });
}
//导出
export function exportWorkOrder(data) {
  return request({
    url: `/tickets/export/retire`,
    method: 'POST',
    responseType: 'blob',
    data
  });
}

// TPC异常
//列表
export function tpcList(data) {
  return request({
    url: `/tickets/tpc/data`,
    method: 'POST',
    data,
  });
}

//详情
export function tpcDetail(data) {
  return request({
    url: `/tickets/tpc/data_detail`,
    method: 'POST',
    data,
  });
}
//导出
export function exportTPC(data) {
  return request({
    url: `/tickets/export/tpc`,
    method: 'POST',
    responseType: 'blob',
    data
  });
}

//网络故障
//列表
export function networkList(params) {
  return request({
    url: `/tickets/network/search`,
    method: 'GET',
    params,
  });
}
//详情
export function networkDetail(params) {
  return request({
    url: `/tickets/network/ticketInformation`,
    method: 'GET',
    params,
  });
}
//导出
export function exportNetwork(data) {
  return request({
    url: `/tickets/export/network`,
    method: 'POST',
    responseType: 'blob',
    data
  });
}

//服务器搬迁
//列表
export function moveList(data) {
  return request({
    url: `/tickets/move/data`,
    method: 'POST',
    data,
  });
}
//详情
export function moveDetail(params) {
  return request({
    url: `/tickets/move/detail`,
    method: 'GET',
    params,
  });
}
//导出
export function exportMove(data) {
  return request({
    url: `/tickets/export/move`,
    method: 'POST',
    responseType: 'blob',
    data
  });
}
//网络催办列表
export function networkRushTickets(params){
  return request({
    url: `/system/urge/search`,
    method: 'GET',
    params,
  });
}
//网络催办详情
export function networkRushTicketDetail(params){
  return request({
    url: `/system/urge/detail`,
    method: 'GET',
    params,
  });
}

//PO单数据
export function itPOservice(data) {
  return request({
    url: `/tickets/it_po/data`,
    method: 'POST',
    data
  });
}

export function itPOserviceDetail(params) {
  return request({
    url: `/tickets/it_po/detail`,
    method: 'GET',
    params,
  });
}

export function itPOserviceAllSpecial(params) {
  return request({
    url: `/tickets/it_po/all-special`,
    method: 'GET',
    params,
  });
}

export function exportITPO(data) {
  return request({
    url: `/tickets/export/po`,
    method: 'POST',
    responseType: 'blob',
    data
  });
}

//工单管控催办
export function getRemindLogs(params){
  return request({
    url: `/tickets/remindLogs/search`,
    method: 'GET',
    params,
  });
}